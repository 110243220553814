<template>
  <b-container fluid>
    <b-row class="vh-100" align-v="center" align-h="center">
      <b-col style="min-width: 300px; max-width: 400px">
        <h1>Changer mon mot de passe</h1>
        <p>
          <em>
            Votre mot de passe expire le
            <strong>{{ expirationMotDePasse }}</strong
            >, nous vous recommandons d'en changer d&egrave;s maintenant.
          </em>
        </p>
        <PasswordChangerComponent @change="onChange"></PasswordChangerComponent>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PasswordChangerComponent from "../../components/backoffice/PasswordChangerComponent.vue";
export default {
  name: "ChangePasswordView",
  components: { PasswordChangerComponent },
  computed: {
    expirationMotDePasse() {
      return new Date(
        this.$store.state.auth.user.expirationMotDePasse
      ).toLocaleString();
    },
  },
  methods: {
    onChange() {
      this.$router.push({ name: "accueil" });
    },
  },
};
</script>
